<template>
  <section>
    <b-sidebar
      id="project-list"
      v-model="show_sidebar"
      bg-variant="white"
      left
      shadow
    >
      <!-- Stats -->
      <div
        class="d-flex justify-content-between px-2"
        v-if="!hide_project_type_count"
      >
        <div
          class="d-flex flex-column align-items-center cursor-pointer"
          @click="project_filter()"
        >
          <b-avatar variant="light-primary">
            <b-icon-list-ul />
          </b-avatar>
          <label class="mt-25">全部</label>

          <b-badge variant="light-primary">
            {{ project_bak_list.length }}
          </b-badge>
        </div>
        <div
          class="d-flex flex-column align-items-center cursor-pointer"
          @click="project_filter('办公')"
        >
          <b-avatar variant="light-danger">
            <b-icon-pen-fill />
          </b-avatar>
          <label class="mt-25">办公</label>
          <b-badge variant="light-danger">
            {{ project_type_count["办公"] }}
          </b-badge>
        </div>

        <div
          class="d-flex flex-column align-items-center cursor-pointer"
          @click="project_filter('住宅')"
        >
          <b-avatar variant="light-success">
            <b-icon-cup-fill />
          </b-avatar>
          <label class="mt-25">住宅</label>
          <b-badge variant="light-success">
            {{ project_type_count["住宅"] }}
          </b-badge>
        </div>

        <div
          class="d-flex flex-column align-items-center cursor-pointer"
          @click="project_filter('综合')"
        >
          <b-avatar variant="light-warning">
            <b-icon-bag-fill />
          </b-avatar>
          <label class="mt-25">综合</label>
          <b-badge variant="light-warning">
            {{ project_type_count["综合"] }}
          </b-badge>
        </div>

        <div
          class="d-flex flex-column align-items-center cursor-pointer"
          @click="project_filter('工业')"
        >
          <b-avatar variant="light-info">
            <b-icon-wrench />
          </b-avatar>
          <label class="mt-25">工业</label>
          <b-badge variant="light-info">
            {{ project_type_count["工业"] }}
          </b-badge>
        </div>

        <div
          class="d-flex flex-column align-items-center cursor-pointer"
          @click="project_filter('其他')"
        >
          <b-avatar variant="light-secondary">
            <b-icon-three-dots />
          </b-avatar>
          <label class="mt-25">其它</label>
          <b-badge variant="light-secondary">
            {{ project_type_count["其他"] }}
          </b-badge>
        </div>
      </div>

      <!-- Search -->
      <div class="px-2 my-2">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="query_project_name"
            :placeholder="$t('Search by Project Name')"
            @keyup="project_search"
          />
        </b-input-group>
      </div>

      <!-- Project List -->
      <div class="overflow-hidden" style="height: calc(100% - 165px)">
        <vue-perfect-scrollbar class="scroll-area h-100">
          <h5
            v-if="!project_list || project_list.length === 0"
            class="text-center mt-3"
          >
            {{ $t("No Data") }}
          </h5>

          <b-list-group v-else flush>
            <b-list-group-item
              v-for="(projectItem, projectIndex) in project_list"
              :id="'popover-target-' + projectIndex"
              :key="projectItem.id"
              class="flex-column align-items-start"
              style="cursor: pointer"
              @mouseover="select_project(projectIndex)"
              @click.prevent="to_project(projectItem)"
            >
              <b-popover
                id="projectInfo"
                :target="'popover-target-' + projectIndex"
                delay="100"
                placement="left"
                triggers="hover"
              >
                <template v-slot:title>
                  <div
                    :style="
                      'background: url(' +
                      projectItem.images[0] +
                      ') center center / cover;'
                    "
                    class="rounded-top"
                    style="height: 15rem; margin: -0.65rem -1.21rem"
                  ></div>
                </template>

                <h4 class="font-weight-bolder mt-50">
                  {{ projectItem.project_name }}
                </h4>

                <small class="d-flex ajust-items-center mb-1">
                  <feather-icon class="mr-50" icon="MapPinIcon" />
                  {{ projectItem.city || "N/A" }}
                  <feather-icon class="mr-50 ml-1" icon="HomeIcon" />
                  {{ projectItem.building_type || "N/A" }}
                  <feather-icon class="mr-50 ml-1" icon="CalendarIcon" />
                  {{
                    projectItem.building_time
                      ? momentJs(projectItem.building_time).format("Y")
                      : "N/A"
                  }}
                </small>

                <div
                  v-if="!!projectItem.telephone"
                  class="d-flex align-items-center"
                >
                  <feather-icon class="mr-50" icon="PhoneIcon" />
                  {{ projectItem.telephone || "N/A" }}
                </div>
                <div v-if="!!projectItem.fax" class="d-flex align-items-center">
                  <feather-icon class="mr-50" icon="PrinterIcon" />
                  {{ projectItem.fax || "N/A" }}
                </div>
                <div
                  v-if="!!projectItem.email"
                  class="d-flex align-items-center"
                >
                  <feather-icon class="mr-50" icon="MailIcon" />
                  {{ projectItem.email || "N/A" }}
                </div>
                <div v-if="!!projectItem.web" class="d-flex align-items-center">
                  <feather-icon class="mr-50" icon="GlobeIcon" />
                  {{ projectItem.web || "N/A" }}
                </div>
                <div
                  v-if="!!projectItem.address"
                  class="d-flex align-items-center"
                >
                  <feather-icon class="mr-50" icon="MapPinIcon" />
                  {{ projectItem.address || "N/A" }}
                </div>

                <hr style="margin-left: -1.21rem; margin-right: -1.21rem" />
                <small
                  style="
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 5;
                    overflow: hidden;
                  "
                >
                  {{ projectItem.description }}
                </small>
                <div class="text-right mt-50">
                  <b-button
                    :to="{
                      name: 'index-project-detail',
                      params: {
                        project_id: projectItem.project_id,
                        title: '项目主页',
                        project_name: projectItem.project_name,
                      },
                    }"
                    variant="flat-primary"
                    @click="to_project(projectItem)"
                  >
                    {{ $t("enter") }}

                    <b-icon-arrow-right-circle />
                  </b-button>
                </div>

                <hr style="margin-left: -1.21rem; margin-right: -1.21rem" />
                <b-row class="mb-50">
                  <b-col cols="4">
                    <div class="d-flex align-items-center">
                      <b-avatar class="mr-50" rounded variant="light-danger">
                        <b-icon-building />
                      </b-avatar>
                      <b-card-text>
                        <h5 class="my-0 text-truncate">
                          {{ projectItem.building_area || "N/A" }}
                          <small class="text-muted"> m<sup>2</sup></small>
                        </h5>
                        <label class="my-0">建筑面积</label>
                      </b-card-text>
                    </div>
                  </b-col>

                  <b-col cols="4">
                    <div class="d-flex align-items-center">
                      <b-avatar class="mr-50" rounded variant="light-success">
                        <b-icon-stack />
                      </b-avatar>
                      <b-card-text>
                        <h5 class="my-0">
                          {{ projectItem.building_level || "N/A" }}
                          <small class="text-muted"> 层</small>
                        </h5>
                        <label class="my-0">建筑楼层</label>
                      </b-card-text>
                    </div>
                  </b-col>

                  <b-col cols="4">
                    <div class="d-flex align-items-center">
                      <b-avatar class="mr-50" rounded variant="light-warning">
                        <b-icon-clock />
                      </b-avatar>
                      <b-card-text>
                        <h5 class="my-0">
                          {{
                            projectItem.building_time
                              ? momentJs(projectItem.building_time).format("Y")
                              : "N/A"
                          }}

                          <small class="text-muted"> 年</small>
                        </h5>
                        <label class="my-0">竣工年份</label>
                      </b-card-text>
                    </div>
                  </b-col>
                </b-row>
              </b-popover>

              <b-card-text class="d-flex">
                <div
                  :style="
                    'background: url(' +
                    projectItem.images[0] +
                    ') center center / cover;'
                  "
                  class="rounded"
                  style="width: 100px; height: 75px"
                ></div>
                <div
                  class="d-flex flex-column justify-content-between"
                  style="width: calc(100% - 100px); padding-left: 1.25rem"
                >
                  <h5 class="font-weight-bolder">
                    {{ projectItem.project_name }}
                  </h5>
                  <small class="text-muted">
                    <feather-icon icon="TagIcon" />
                    {{ projectItem.city || "N/A" }} &sdot;
                    {{ projectItem.building_type || "N/A" }}
                    &sdot;
                    {{
                      projectItem.building_time
                        ? momentJs(projectItem.building_time).format("Y")
                        : "N/A"
                    }}
                  </small>
                </div>
              </b-card-text>
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>
      </div>
    </b-sidebar>

    <!-- Map -->
    <div
      id="project-list-map"
      class="w-100 position-absolute"
      style="height: 95vh; margin-left: -2rem; margin-top: -3rem"
    ></div>
  </section>
</template>

<script>
import store from "@/store";
import { GaodeMap } from "@antv/l7-maps";
import { PointLayer, Scene } from "@antv/l7";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { VBPopover } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  computed,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "@vue/composition-api";
import { momentJs } from "@/libs/utils/moment";
import { showToast } from "@/libs/utils/showToast";
import router from "@/router";

window.l7_map = null;
export default {
  name: "ProjectList",
  components: {
    VuePerfectScrollbar,
  },
  directives: {
    "b-popover": VBPopover,
    Ripple,
  },
  setup() {
    const event = reactive({
      show_sidebar: false,
      map_style: computed(() => {
        const skin = store.state.appConfig.layout.skin;
        return skin === "dark" ? "dark" : "light";
      }),
      project_list: [],
      project_bak_list: [],
      project_type_count: {
        办公: 0,
        住宅: 0,
        综合: 0,
        工业: 0,
        其他: 0,
      },
      selected: 0,
      scene: null,
      selected_layer: null,
      project_list_layer: null,
      popup_layer: null,
      query_project_name: "",
      hide_project_type_count: false,
    });

    /**
     * 监听地图主题，更新地图主题
     */
    watch(
      () => event.map_style,
      (map_style) => {
        console.log("map_style", map_style);
        window.l7_map.setMapStyle(map_style);
      }
    );

    watch(
      () => event.project_list,
      (project_list) => {
        //锦什坊项目隐藏项目类型统计
        if (project_list.length === 1 && project_list[0].project_id == 72) {
          console.log("project_list", project_list);
          event.hide_project_type_count = true;
        }
      }
    );

    /**
     * 设置地图图层
     */
    const setLayers = () => {
      const selected = [];
      let project_list = JSON.parse(JSON.stringify(event.project_list));
      if (project_list.length === 0) {
        event.selected_layer = null;
        event.project_list_layer = null;
        event.popup_layer = null;
        window.l7_map.removeAllLayer();
      } else {
        selected.push(project_list[event.selected]);
        project_list.splice(event.selected, 1);
        //设置除选中点外的其他点图层
        if (event.project_list_layer != null) {
          event.project_list_layer.setData(project_list);
          window.l7_map.render();
        } else {
          event.project_list_layer = new PointLayer({})
            .source(project_list, {
              parser: {
                type: "json",
                x: "lng",
                y: "lat",
              },
            })
            .shape("circle")
            .animate(false)
            .size(8)
            .color("#28c76f")
            .style({
              opacity: 0.3,
            });
          window.l7_map.addLayer(event.project_list_layer);
        }
        //设置选中点图层
        if (event.selected_layer !== null) {
          event.selected_layer.setData(selected);
          window.l7_map.render();
        } else {
          event.selected_layer = new PointLayer({})
            .source(selected, {
              parser: {
                type: "json",
                x: "lng",
                y: "lat",
              },
            })
            .shape("circle")
            .animate(true)
            .size(60)
            .color("#28c76f");
          window.l7_map.addLayer(event.selected_layer);
        }
      }
    };
    /**
     * 获取项目列表
     */
    const getProjects = (query_project_name = "") => {
      let payload = { pagesize: 10000 };
      query_project_name = query_project_name.replace(/[ ]/g, "");
      if (query_project_name) {
        payload.project_name = query_project_name;
      }
      store
        .dispatch("projects/fetchProjects", payload)
        .then((response) => {
          showToast(null, "成功！", "项目列表获取成功！");
          event.show_sidebar = true;
          event.project_list = response.data;
          event.project_bak_list = response.data;

	        //单个项目时，直接进入详情页
          if (query_project_name === "" && event.project_list.length === 1) {
            to_project(event.project_list[0]);
          }

          response.data.map((item) => {
            event.project_type_count[item.building_type] += 1;
          });
        })
        .then(() => {
          map_init();
          setTimeout(function () {
            setLayers();
          }, 1500);
        })
        .catch((error) => {
          showToast(error);
        });
    };
    /**
     * 地图组件初始化
     */
    const map_init = () => {
      window.l7_map = new Scene({
        id: "project-list-map",
        map: new GaodeMap({
          pitch: 0,
          style: event.map_style,
          center: [100, 39],
          zoom: 3.7,
          zoomEnable: false,
          dragEnable: false,
          token: "aeb928d0fa255249be59173a8d5fa016",
        }),
      });
    };

    /**
     * 选中项目，更新地图图层
     * @param projectIndex
     */
    const select_project = (projectIndex) => {
      event.selected = projectIndex;
      setLayers();
    };

    /**
     * 设置选中的项目
     * @param project
     */
    const to_project = (project) => {
      localStorage.setItem("project_id", project.project_id);
      localStorage.setItem("project-selected", JSON.stringify(project));
      store.commit("projects/UPDATE_PROJECT_SELECTED", project);
      let routerParams = {
        name: "index-project-detail",
        params: {
          project_id: project.project_id,
          title: "项目主页",
          project_name: project.project_name,
        },
      };
      router.push(routerParams);
    };

    /**
     * 项目类型筛选
     * @param project_type
     */
    const project_filter = (project_type = null) => {
      let project_list = JSON.parse(JSON.stringify(event.project_bak_list));
      if (project_type) {
        project_list = event.project_bak_list.filter(
          (item) => item.building_type === project_type
        );
      }
      event.project_list = project_list;
      setLayers();
    };
    /**
     * 项目名称筛选
     */
    const project_search = () => {
      let project_list = JSON.parse(JSON.stringify(event.project_bak_list));
      if (event.query_project_name) {
        project_list = event.project_bak_list.filter(
          (item) => item.project_name.indexOf(event.query_project_name) >= 0
        );
      }
      event.project_list = project_list;
      setLayers();
    };

    onMounted(() => {
      getProjects();
    });

    return {
      ...toRefs(event),
      select_project,
      to_project,
      getProjects,
      momentJs,
      project_filter,
      project_search,
    };
  },
};
</script>

<style lang="scss">
/*隐藏l7的logo*/
.l7-control-logo.l7-control {
  display: none;
}

.dark-layout .l7-popup-anchor-bottom .l7-popup-tip {
  border-top-color: #283046;
}

.dark-layout .l7-popup-content {
  background: #283046;
}

/* project list */
#project-list .list-group-item {
  padding: 0.75rem 1.25rem;
}

#projectInfo.popover {
  left: 480px !important;
  min-width: 30rem !important;
}

#projectInfo.popover .popover-header,
#projectInfo.popover .popover-body {
  border: none !important;
}

#project-list .ps__rail-x,
#project-list .ps__rail-y {
  z-index: 2;
}
</style>
