<template>
  <div>
    <b-row>
      <!-- 基础信息 -->
      <b-col cols="3">
        <b-card>
          <b-card-title class="text-truncate"> 基础信息</b-card-title>
          <b-card-text>
            <b-row>
              <b-col class="d-flex align-items-center" cols="6">
                <b-avatar class="mr-1" rounded variant="light-danger">
                  <b-icon-pin-map-fill font-scale="1.5"/>
                </b-avatar>
                <div style="min-width: 0">
                  <div class="text-truncate">所在位置</div>
                  <div class="text-truncate text-danger">

                    {{ projectBaseInfo['position'] || '---' }}
                  </div>
                </div>
              </b-col>

              <b-col class="d-flex align-items-center" cols="6">
                <b-avatar class="mr-1" rounded variant="light-info">
                  <b-icon-calendar-date-fill font-scale="1.5"/>
                </b-avatar>
                <div style="min-width: 0">
                  <div class="text-truncate">启用日期</div>
                  <div class="text-truncate text-info">
                    {{ projectBaseInfo['stdate'] || '---' }}
                  </div>
                </div>
              </b-col>

              <b-col class="d-flex align-items-center mt-1" cols="6">
                <b-avatar class="mr-1" rounded variant="light-info">
                  <b-icon-thermometer-sun font-scale="1.5"/>
                </b-avatar>
                <div style="min-width: 0">
                  <div class="text-truncate">供热区域</div>
                  <div class="text-truncate">
                    <span class="text-info">{{ projectBaseInfo['region'] || '---' }}</span>
                  </div>
                </div>
              </b-col>

              <b-col class="d-flex align-items-center mt-1" cols="6">
                <b-avatar class="mr-1" rounded variant="light-primary">
                  <b-icon-share-fill font-scale="1.5"/>
                </b-avatar>
                <div style="min-width: 0">
                  <div class="text-truncate">入网面积</div>
                  <div class="text-truncate">
                    <span class="text-primary">{{ projectBaseInfo['measure'] || '---' }}</span>
                    <small>&nbsp;m<sup>2</sup></small>
                  </div>
                </div>
              </b-col>

              <b-col class="d-flex align-items-center mt-1" cols="6">
                <b-avatar class="mr-1" rounded variant="light-success">
                  <b-icon-building font-scale="1.5"/>
                </b-avatar>
                <div style="min-width: 0">
                  <div class="text-truncate">建筑类别</div>
                  <div class="text-truncate">
                    <span class="text-success">{{ projectBaseInfo['type'] || '---' }}</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <b-card>
          <b-card-title class="text-truncate"> 平米单耗</b-card-title>
          <b-card-text>
            <b-row>
              <b-col cols="4">
                <b-alert show variant="danger" class="mb-0">
                  <div class="alert-body text-center py-2">
                    <b-icon-thermometer-half font-scale="1.5" class="my-50"/>
                    <p class="mb-1">热</p>
                    <div
                        class="text-danger font-digit text-truncate"
                        style="font-size: 2rem"
                    >
                      20.09
                    </div>
                    <div class="mb-25">
                      <label>GJ/m<sup>2</sup>d</label>
                    </div>
                    <b-badge variant="light-danger" style="font-weight: normal">
                      <div class="mb-25">排名</div>
                      <span style="font-size: 1.2rem">31</span>
                      / 158
                    </b-badge>
                  </div>
                </b-alert>
              </b-col>

              <b-col cols="4">
                <b-alert show variant="success" class="mb-0">
                  <div class="alert-body text-center py-2">
                    <b-icon-droplet-fill class="my-50" font-scale="1.5"/>
                    <p class="mb-1">水</p>
                    <div
                        class="text-success font-digit text-truncate"
                        style="font-size: 2rem"
                    >
                      1.66
                    </div>
                    <div class="mb-25">
                      <label>m<sup>3</sup>/m<sup>2</sup>d</label>
                    </div>
                    <b-badge
                        variant="light-success"
                        style="font-weight: normal"
                    >
                      <div class="mb-25">排名</div>
                      <span style="font-size: 1.2rem">35</span>
                      / 158
                    </b-badge>
                  </div>
                </b-alert>
              </b-col>

              <b-col cols="4">
                <b-alert show variant="warning" class="mb-0">
                  <div class="alert-body text-center py-2">
                    <b-icon-lightning-fill class="my-50" font-scale="1.5"/>
                    <p class="mb-1">电</p>
                    <div
                        class="text-warning font-digit text-truncate"
                        style="font-size: 2rem"
                        id="tooltip-target-3"
                    >
                      80.83
                    </div>
                    <div class="mb-25">
                      <label>kWh/m<sup>2</sup>d</label>
                    </div>
                    <b-badge
                        variant="light-warning"
                        style="font-weight: normal"
                    >
                      <div class="mb-25">排名</div>
                      <span style="font-size: 1.2rem">43</span>
                      / 158
                    </b-badge>
                  </div>
                </b-alert>
              </b-col>

              <!--              <b-col cols="12">-->
              <!--                <div id="container_3" style="height: 12rem" class="mt-1"></div>-->
              <!--              </b-col>-->
            </b-row>
          </b-card-text>
        </b-card>

        <b-card>
          <b-card-title class="text-truncate"> 节能诊断</b-card-title>

          <b-card-text>
            <vue-perfect-scrollbar style="height: 29rem">
              <app-timeline v-if="alarmData['energy_saving']">
                <app-timeline-item
                    v-for="(item, index) in alarmData['energy_saving']"
                    :key="index"
                    :time="item['create_date']"
                    :title="item['fault_name']"
                    :variant="
                    item['degree'] === '提醒'
                      ? 'info'
                      : item['degree'] === '一般'
                      ? 'warning'
                      : 'danger'
                  "
                    class="pb-1"
                >
                </app-timeline-item>
              </app-timeline>
              <p v-else class="text-center" style="margin-top: 20%">
                暂无诊断数据
              </p>
            </vue-perfect-scrollbar>
          </b-card-text>
        </b-card>
      </b-col>

      <!-- 建筑图片 -->
      <b-col
          cols="6"
          style="pointer-events: none; display: flex; flex-direction: column"
      >
        <b-card
            style="
            height: 520px;
            background-image: url(/images/heating_plant.png);
            background-position: center bottom;
            background-size: contain;
            background-repeat: no-repeat;
          "
        >
          <b-card-text class="position-relative">
            <h1
                class="font-title text-center mt-1 mb-0 text-dark"
                style="font-size: 2rem; pointer-events: auto"
            >
              青岛能源站
            </h1>

            <div
                class="position-absolute text-primary"
                style="top: 9rem; left: 4rem"
            >
              <span>一次供压</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40011'] &&
                    realTimeParams['_40011']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40011"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">℃</span>
              </div>
            </div>
            <div
                class="position-absolute text-primary"
                style="top: 9rem; left: 10rem"
            >
              <span>一次供温</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40003'] &&
                    realTimeParams['_40003']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40003"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">℃</span>
              </div>
            </div>

            <div
                class="position-absolute text-primary"
                style="top: 9rem; left: 15rem"
            >
              <span>调节阀1开度</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40081'] &&
                    realTimeParams['_40081']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40081"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">%</span>
              </div>
            </div>

            <div
                class="position-absolute text-primary"
                style="top: 6rem; left: 34rem"
            >
              <span>二次供热瞬时流量</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40716'] &&
                    realTimeParams['_40716']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40716"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">%</span>
              </div>
            </div>

            <div
                class="position-absolute text-primary"
                style="top: 9rem; left: 34rem"
            >
              <span>二次供热瞬时热量</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40712'] &&
                    realTimeParams['_40712']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40712"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">%</span>
              </div>
            </div>

            <div
                class="position-absolute text-primary"
                style="top: 9rem; left: 43.5rem"
            >
              <span>二次供压</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40015'] &&
                    realTimeParams['_40015']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40015"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>

                <span class="ml-25">℃</span>
              </div>
            </div>

            <div
                class="position-absolute text-primary"
                style="top: 9rem; left: 48.5rem"
            >
              <span>二次供温</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40007'] &&
                    realTimeParams['_40007']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40007"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>

                <span class="ml-25">℃</span>
              </div>
            </div>


            <div
                class="position-absolute text-info"
                style="top: 23rem; left: 5rem"
            >
              <span>一次回压</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40013'] &&
                    realTimeParams['_40013']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40013"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">℃</span>
              </div>
            </div>

            <div
                class="position-absolute text-info"
                style="top: 23rem; left: 11rem"
            >
              <span>一次回温</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40005'] &&
                    realTimeParams['_40005']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40005"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">℃</span>
              </div>
            </div>

            <div
                class="position-absolute text-info"
                style="top: 23rem; left: 16rem"
            >
              <span>一次供瞬时流量</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40021'] &&
                    realTimeParams['_40021']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40021"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">m<sup>3</sup>/h</span>
              </div>
            </div>

            <div
                class="position-absolute text-info"
                style="top: 26rem; left: 16rem"
            >
              <span>一次供瞬时热量</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40019'] &&
                    realTimeParams['_40019']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40019"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">℃</span>
              </div>
            </div>

            <div
                class="position-absolute text-success"
                style="top: 14.5rem; left: 35rem"
            >
              <span>1#循环泵频率</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40087'] &&
                    realTimeParams['_40087']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40087"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">Hz</span>
              </div>
            </div>

            <div
                class="position-absolute text-success"
                style="top: 14.5rem; left: 43.5rem"
            >
              <span>二次回压</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40017'] &&
                    realTimeParams['_40017']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40017"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">Hz</span>
              </div>
            </div>

            <div
                class="position-absolute text-success"
                style="top: 14.5rem; left: 48rem"
            >
              <span>二次回温</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40009'] &&
                    realTimeParams['_40009']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40009"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">Hz</span>
              </div>
            </div>

            <div
                class="position-absolute text-success"
                style="top: 25rem; left: 34rem"
            >
              <span>2#循环泵频率</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40095'] &&
                    realTimeParams['_40095']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40095"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">Hz</span>
              </div>
            </div>

            <div
                class="position-absolute text-success"
                style="top: 19rem; left: 44rem"
            >
              <span>1#补水泵频率</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40103'] &&
                    realTimeParams['_40103']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40103"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">Hz</span>
              </div>
            </div>

            <div
                class="position-absolute text-success"
                style="top: 27rem; left: 43rem"
            >
              <span>1#补水泵频率</span>
              <div>
                <span
                    v-if="
                    realTimeParams['_40103'] &&
                    realTimeParams['_40103']['EnvDataH']
                  "
                >
                  {{ realTimeParams["_40103"]["EnvDataH"] }}
                </span>
                <span v-else>0.00</span>
                <span class="ml-25">Hz</span>
              </div>
            </div>












          </b-card-text>
        </b-card>

        <b-card>
          <b-card-title class="text-truncate"> 累计电耗</b-card-title>
          <b-card-text>
            <div id="container_1" style="height: 12rem"></div>
          </b-card-text>
        </b-card>

        <b-card>
          <b-card-title class="text-truncate"> 累计热耗</b-card-title>
          <b-card-text>
            <div id="container_2" style="height: 12rem"></div>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col cols="3">
        <!-- 气象信息 -->
        <b-card>
          <b-card-title class="text-truncate"> 气象信息</b-card-title>
          <b-card-text>
            <b-row>
              <b-col class="text-center" cols="3">
                <p class="mb-1">最高温度</p>
                <div
                    class="text-danger font-digit text-truncate"
                    style="font-size: 2rem"
                >
                  8
                </div>
                <label>℃</label>
              </b-col>

              <b-col class="text-center" cols="3">
                <p class="mb-1">最低温度</p>
                <div
                    class="text-primary font-digit text-truncate"
                    style="font-size: 2rem"
                >
                  2
                </div>
                <label>℃</label>
              </b-col>

              <b-col class="text-center" cols="3">
                <p class="mb-1">阴晴雨雪</p>
                <div
                    class="text-info font-digit text-truncate"
                    style="font-size: 2rem"
                >
                  晴
                </div>
              </b-col>

              <b-col class="text-center" cols="3">
                <p class="mb-1">室外温度</p>
                <div
                    class="text-warning font-digit text-truncate"
                    style="font-size: 2rem"
                >
                  <span
                      v-if="
                      realTimeParams['_40001'] &&
                      realTimeParams['_40001']['EnvDataH']
                    "
                  >
                    {{
                      Math.round(realTimeParams["_40001"]["EnvDataH"] * 100) /
                      100
                    }}
                  </span>
                  <span v-else>0.00</span>
                </div>
                <label>℃</label>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <!-- 运行评价 -->
        <b-card>
          <b-card-title class="text-truncate"> 运行评价</b-card-title>
          <b-card-text>
            <b-row>
              <b-col cols="12">
                <div id="container_3" style="height: 11.3rem"></div>
              </b-col>

              <b-col cols="12" class="pt-2" style="padding-left: 30%">
                <div class="d-flex align-items-center">
                  <b-avatar
                      rounded
                      variant="light-success"
                      class="mr-1"
                      size="36"
                  >
                    <b-icon-graph-down scale="1.5"></b-icon-graph-down>
                  </b-avatar>
                  <div>
                    安全评价<br/>
                    <span class="h3 text-success">
                      <span v-if="operationalEvaluation['security']">
                        {{
                          Math.round(
                              operationalEvaluation["security"][
                              operationalEvaluation["security"].length - 1
                                  ]["sec_score"] * 100
                          ) / 100
                        }}
                      </span>
                      <span v-else>0.00</span>
                    </span>
                    <span>&nbsp;%</span>
                  </div>
                </div>

                <div class="d-flex align-items-center mt-50">
                  <b-avatar
                      rounded
                      variant="light-danger"
                      class="mr-1"
                      size="36"
                  >
                    <b-icon-shield-check scale="1.5"></b-icon-shield-check>
                  </b-avatar>
                  <div>
                    功能评价<br/>
                    <span class="h3 text-danger">
                      <span v-if="operationalEvaluation['functiom']">
                        {{
                          Math.round(
                              operationalEvaluation["functiom"][
                              operationalEvaluation["functiom"].length - 1
                                  ]["fun_score"] * 100
                          ) / 100
                        }}
                      </span>
                      <span v-else>0.00</span></span
                    >
                    <span>&nbsp;%</span>
                  </div>
                </div>

                <div class="d-flex align-items-center mt-50">
                  <b-avatar
                      rounded
                      variant="light-warning"
                      class="mr-1"
                      size="36"
                  >
                    <b-icon-arrow-clockwise
                        scale="1.5"
                    ></b-icon-arrow-clockwise>
                  </b-avatar>
                  <div>
                    节能评价<br/>
                    <span class="h3 text-warning">
                      <span v-if="operationalEvaluation['energy_saving']">
                        {{
                          Math.round(
                              operationalEvaluation["energy_saving"][
                              operationalEvaluation["energy_saving"].length - 1
                                  ]["es_score"] * 100
                          ) / 100
                        }}
                      </span>
                      <span v-else>0.00</span></span
                    >

                    <span>&nbsp;%</span>
                  </div>
                </div>
              </b-col>

              <!--              <b-col cols="12">-->
              <!--                <div id="container_2" style="height: 12rem" class="mt-1"></div>-->
              <!--              </b-col>-->
            </b-row>
          </b-card-text>
        </b-card>

        <b-card>
          <b-card-title class="text-truncate"> 告警</b-card-title>
          <b-tabs fill>
            <b-tab title="安全告警" active>
              <b-card-text>
                <vue-perfect-scrollbar style="height: 21.8rem">
                  <app-timeline v-if="alarmData['security']">
                    <app-timeline-item
                        v-for="(item, index) in alarmData['security']"
                        :key="index"
                        :time="item['create_date']"
                        :title="item['fault_name']"
                        :variant="
                        item['degree'] === '提醒'
                          ? 'info'
                          : item['degree'] === '一般'
                          ? 'warning'
                          : 'danger'
                      "
                        class="pb-1"
                    >
                    </app-timeline-item>
                  </app-timeline>
                  <p v-else class="text-center" style="margin-top: 20%">
                    暂无安全告警数据
                  </p>
                </vue-perfect-scrollbar>
              </b-card-text>
            </b-tab>
            <b-tab title="功能告警">
              <b-card-text>
                <vue-perfect-scrollbar style="height: 21.8rem">
                  <app-timeline v-if="alarmData['functiom']">
                    <app-timeline-item
                        v-for="(item, index) in alarmData['functiom']"
                        :key="index"
                        :time="item['create_date']"
                        :title="item['fault_name']"
                        :variant="
                        item['degree'] === '提醒'
                          ? 'info'
                          : item['degree'] === '一般'
                          ? 'warning'
                          : 'danger'
                      "
                        class="pb-1"
                    >
                    </app-timeline-item>
                  </app-timeline>
                  <p v-else class="text-center" style="margin-top: 20%">
                    暂无功能告警数据
                  </p>
                </vue-perfect-scrollbar>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import StatisticCardWithAreaChart from "@/@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import {$themeColors} from "@themeConfig";
import {kFormatter} from "@/@core/utils/filter";
import VueApexCharts from "vue-apexcharts";
import {momentJs} from "@/libs/utils/moment";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import useJwt from "@/libs/auth/jwt/useJwt";
import {Gauge, Line, Column, DualAxes} from "@antv/g2plot";

export default {
  name: "Qingdao",
  components: {
    AppTimeline,
    AppTimelineItem,
    VuePerfectScrollbar,
  },
  data() {
    return {
      intervalFlag: null,
      projectBaseInfo: {},
      realTimeParams: {},
      alarmData: {},
      operationalEvaluation: {},
      chart_1: null,
      chart_2: null,
      chart_3: null,
    };
  },
  watch: {},
  methods: {
    kFormatter,

    doGetPageData() {
      this.getProjectBaseInfo();
      this.getAlarmData();
      this.getEnergyData();
      this.getRealTimeData();
      this.getOperationalEvaluation();
    },
    getProjectBaseInfo() {
      const axios = useJwt.axiosIns;
      axios.get("/api/qdnyz/params/project-base-info", {project_id: 83}).then((res) => {
        console.log("project_base_info", res.data);
        this.projectBaseInfo = res.data || {};
      });
    },
    getAlarmData() {
      const axios = useJwt.axiosIns;
      axios.get("/api/qdnyz/params/alarm", {project_id: 83}).then((res) => {
        console.log("alarm", res.data);
        this.alarmData = res.data;
      });
    },
    getEnergyData() {
      const axios = useJwt.axiosIns;
      axios.get("/api/qdnyz/params/energy", {project_id: 83}).then((res) => {
        const chart_1Data = res.data['_40742']
        const chart_2Data = res.data['_40025']
        this.chart_1.changeData(chart_1Data)
        this.chart_2.changeData(chart_2Data)
      });
    },
    getRealTimeData() {
      const axios = useJwt.axiosIns;
      axios
          .get("/api/qdnyz/params/real-time-params", {project_id: 83})
          .then((res) => {
            this.realTimeParams = res.data;
          });
    },
    getOperationalEvaluation() {
      const axios = useJwt.axiosIns;
      axios
          .get("/api/qdnyz/params/operational-evaluation", {project_id: 83})
          .then((res) => {
            this.operationalEvaluation = res.data;
          });
    },
    operationalEvaluationChartInit() {
      const gauge = new Gauge("container_3", {
        percent: 0.7222,
        type: "meter",
        innerRadius: 0.75,
        range: {
          ticks: [0, 1 / 3, 2 / 3, 1],
          color: ["#F4664A", "#FAAD14", "#30BF78"],
        },
        indicator: {
          pointer: {
            style: {
              stroke: "#D0D0D0",
            },
          },
          pin: {
            style: {
              stroke: "#D0D0D0",
            },
          },
        },
        statistic: {
          content: {
            style: {
              // fontSize: "36px",
              lineHeight: "40px",
              color: "#D0D0D0",
            },
          },
        },
      });
      gauge.render();
      this.chart_3 = gauge;
    },
    lineChart_1Init() {
      const lineChart_1 = new Line("container_1", {
        data: [],
        xField: "time",
        yField: "value",
        yAxis: {
          grid: null,
        },
        legend: {
          position: "top",
        },
        smooth: true,
        // 配置折线趋势填充
        area: {
          style: {
            fillOpacity: 0.15,
          },
        },
        animation: {
          appear: {
            animation: "wave-in",
            duration: 3000,
          },
        },
      });

      lineChart_1.render();
      this.chart_1 = lineChart_1;
    },
    lineChart_2Init() {
      const lineChart_2 = new Line("container_2", {
        data: [],
        xField: "time",
        yField: "value",
        yAxis: {
          grid: null,
        },
        legend: {
          position: "top",
        },
        smooth: true,
        // 配置折线趋势填充
        area: {
          style: {
            fillOpacity: 0.15,
          },
        },
        animation: {
          appear: {
            animation: "wave-in",
            duration: 3000,
          },
        },
      });

      lineChart_2.render();
      this.chart_2 = lineChart_2;
    },
  },
  mounted() {
    this.lineChart_1Init()
    this.lineChart_2Init()
    this.operationalEvaluationChartInit()
    // 获取数据
    this.doGetPageData();
    this.intervalFlag = setInterval(this.doGetPageData, 120 * 1000);
    // 初始化场景
    // this.initScene("babylonJsCanvas")
  },
  destroyed() {
    clearInterval(this.intervalFlag);
  },
};
</script>

<style lang="scss" scoped>
.adsk-viewing-viewer {
  background: none !important;
}

.homeViewWrapper {
  display: none !important;
}

.offsetMargin {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
</style>
